<template>
  <div class="flex-grow-1">

    <div class="d-flex" v-if="folders.length > 0">
      <v-sheet
        @click.stop="$emit('changeFolder', item)"
        class="pa-1 ma-2 d-flex flex-column"
        v-for="item in folders"
        :key="item.path"
        style="cursor: pointer"
      >
        <v-icon class="mb-1" x-large>mdi-folder</v-icon>
        <span class="text-body-1 text-center">{{ item.name }}</span>
      </v-sheet>
    </div>
    <v-divider v-if="folders.length > 0 && files.length > 0" class="my-4"></v-divider>
    <div class="d-flex flex-column mb-2" v-if="files.length > 0">
      <CurrentFile
        :idEstudio="idEstudio"
        :currentFile="item"
        v-for="item in files"
        :key="item.path"
        @deleteFolder="v => $emit('deleteFolder', v)"
        @reload="$emit('reload')"
        :sub="sub"
      />
    </div>

    <div v-else-if="folders.length == 0 && files.length == 0 && !isFile" class="d-flex justify-center align-center">
      <v-subheader>Esta carpeta está vacía</v-subheader>
    </div>

    <div v-else-if="isFile">
      <CurrentFile :idEstudio="idEstudio" :currentFile="currentFolder" @deleteFolder="v => $emit('deleteFolder', v)" :sub="sub" />
    </div>

  </div>
</template>

<script>
export default {
  components: {
    CurrentFile: () => import("./CurrentFile.vue"),
  },
  props: {
    currentFolder: Object | Array,
    idEstudio: Number,
    sub: {
      type: String,
      default: ''
    },
  },
  computed: {
    folders() {
      if (!this.currentFolder.children) return [];
      return this.currentFolder.children.filter((x) => !x.is_file);
    },
    files() {
      if (!this.currentFolder.children) return [];
      return this.currentFolder.children.filter((x) => x.is_file);
    },
    isFile() {
      return this.currentFolder.is_file
    }
  },
};
</script>
